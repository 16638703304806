@tailwind base;
@tailwind utilities;
@tailwind components;

@import "@fontsource/montserrat/400.css";
@import "@fontsource/montserrat/500.css";
@import "@fontsource/montserrat/600.css";
@import "@fontsource/montserrat/700.css";

html,
body,
#root {
  height: 100%;
  font-family: "Montserrat", sans-serif;
}

/* outside @screen the smallest size */
.main-padding {
  text-align: center;
  padding: 35px 30px 40px 30px;
}

/* bigger than md */
@screen md {
  .main-padding {
    text-align: left;
    padding: 50px 11px 50px 31px;
  }
}

/* bigger than lg */
@screen lg {
  .main-padding {
    padding: 180px 50px 180px 75px;
  }
}

/* reuse tailwind classes */
@layer components {
  .custom-input {
    @apply bg-[#fafafa] text-[#666] p-3 my-5 block w-full border border-solid rounded-sm border-[#eaeaea];
  }
  .legal-stuff {
    @apply text-lg;
  }
  .legal-stuff h1 {
    @apply pb-6 font-bold text-center text-xl;
  }
  .legal-stuff p,
  .legal-stuff ul,
  .legal-stuff ol {
    @apply pb-4;
  }
  .legal-stuff li {
    @apply pb-2 ml-8;
    list-style-type: upper-roman;
  }
}
